<template>
  <div :id="signatureID">
    <span style="font-family: Helvetica, serif; color: #1a2d50">
      <table cellspacing="0" cellpadding="0" border="0">
        <tr>
          <td align="center" bgcolor="#ffffff">
            <table
              style="
                table-layout: fixed;
                overflow: hidden;
                display: block;
                border-collapse: collapse;
                padding: 0px;
                border-spacing: 0px;
              "
              height="200"
              cellpadding="0"
              cellspacing="0"
              border="0"
            >
              <tr>
                <td
                  style="
                    padding: 0px 10px 0px 10px;
                    border-right: 1px solid #333333;
                  "
                  width="200"
                  height="200"
                >
                  <img
                    src="../assets/img/signature/logo.jpg"
                    style="
                      width: 100%;
                      height: auto;
                      display: block;
                      color: #1a2d50;
                      font-size: 16px;
                      font-family: Helvetica, serif;
                    "
                    alt=" TrioMarkets™ - Trustworthy Transparent Trading"
                    title=" TrioMarkets™ - Trustworthy Transparent Trading"
                  />
                </td>
                <td
                  bgcolor="#ffffff"
                  valign="top"
                  style="padding: 0px 10px 0px 10px"
                >
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    height="200"
                  >
                    <tr height="35">
                      <td width="8"></td>
                      <td>
                        <font face="helvetica" color="#1a2d50"
                          ><span
                            style="
                              font-family: Helvetica, serif;
                              font-size: 14pt;
                            "
                            ><b>{{ signature.name }}</b></span
                          ></font
                        >
                      </td>
                    </tr>
                    <tr height="25">
                      <td width="8"></td>
                      <td style="vertical-align: top">
                        <font face="helvetica" color="#1a2d50"
                          ><span
                            style="
                              font-family: Helvetica, serif;
                              font-size: 12pt;
                            "
                            >{{ signature.title }}</span
                          ></font
                        >
                      </td>
                    </tr>

                    <tr>
                      <td width="8"></td>
                      <td>
                        <table border="0" cellpadding="0" cellspacing="0">
                          <tr height="25">
                            <td width="20">
                              <img
                                width="20"
                                height="20"
                                src="../assets/img/signature/position.png"
                              />
                            </td>
                            <td v-if="license == 'edr'" colspan="3">
                              <span style="font-size: 8pt"
                                ><font face="helvetica" color="#1a2d50"
                                  >Grigori Afxentiou, 13&15, Ide Ioannou Court, Office 105, Mesa Geitonia, 4003, Limassol, Cyprus</font
                                ></span
                              >
                            </td>
                            <td v-if="license == 'benor'" colspan="3">
                              <span style="font-size: 8pt"
                                ><font face="helvetica" color="#1a2d50"
                                  >The Catalyst Level 2 Suite 201, Plot 40,
                                  Silicon Avenue Ebene, Mauritius</font
                                ></span
                              >
                            </td>
                            <td v-if="license == 'dubai'" colspan="3">
                              <span style="font-size: 8pt"
                                ><font face="helvetica" color="#1a2d50"
                                  >The Bayswater Tower, Office 1212, Marasi
                                  Drive, Business Bay, Dubai, United Arab
                                  Emirates</font
                                ></span
                              >
                            </td>
                          </tr>
                          <tr height="25">
                            <td width="20">
                              <img
                                width="20"
                                height="20"
                                src="../assets/img/signature/telephone.png"
                              />
                            </td>
                            <td v-if="license == 'edr'" width="150">
                              <span style="font-size: 8pt"
                                ><font face="helvetica" color="#1a2d50"
                                  >+357 22222737</font
                                ></span
                              >
                            </td>
                            <td v-if="license == 'benor'" width="150">
                              <span style="font-size: 8pt"
                                ><font face="helvetica" color="#1a2d50"
                                  >+44 20 376 936 49</font
                                ></span
                              >
                            </td>
                            <td v-if="license == 'dubai'" width="150">
                              <span style="font-size: 8pt"
                                ><font face="helvetica" color="#1a2d50"
                                  >+971 4 242 1234</font
                                ></span
                              >
                            </td>
                            <td v-if="license == 'edr'" width="20">
                              <img
                                width="20"
                                height="20"
                                src="../assets/img/signature/fax.png"
                              />
                            </td>
                            <td v-if="license == 'edr'">
                              <span style="font-size: 8pt"
                                ><font face="helvetica" color="#1a2d50"
                                  >+357 25250310</font
                                ></span
                              >
                            </td>
                          </tr>
                          <tr v-if="signature.mobilePhone" height="25">
                            <td width="20">
                              <img
                                width="20"
                                height="20"
                                src="../assets/img/signature/mobile.png"
                              />
                            </td>
                            <td width="150">
                              <span style="font-size: 8pt"
                                ><font face="helvetica" color="#1a2d50">{{
                                  signature.mobilePhone
                                }}</font></span
                              >
                            </td>
                          </tr>
                          <tr height="25">
                            <td width="20">
                              <img
                                width="20"
                                height="20"
                                src="../assets/img/signature/email.png"
                              />
                            </td>
                            <td width="150">
                              <span style="font-size: 8pt"
                                ><font face="helvetica" color="#1a2d50"
                                  ><a
                                    style="color: #1a2d50"
                                    :href="'mailto:' + signature.email"
                                    >{{ signature.email }}</a
                                  ></font
                                ></span
                              >
                            </td>
                            <td width="20">
                              <img
                                width="20"
                                height="20"
                                src="../assets/img/signature/web.png"
                              />
                            </td>
                            <td v-if="license == 'benor'">
                              <span style="font-size: 8pt"
                                ><font face="helvetica" color="#1a2d50"
                                  ><a
                                    style="color: #1a2d50"
                                    href="https://triomarkets.com"
                                    >triomarkets.com</a
                                  ></font
                                ></span
                              >
                            </td>
                            <td v-if="license == 'dubai'">
                              <span style="font-size: 8pt"
                                ><font face="helvetica" color="#1a2d50"
                                  ><a
                                    style="color: #1a2d50"
                                    href="https://triomarkets.com/enUae"
                                    >triomarkets.com</a
                                  ></font
                                ></span
                              >
                            </td>
                            <td v-if="license == 'edr'">
                              <span style="font-size: 8pt"
                                ><font face="helvetica" color="#1a2d50"
                                  ><a
                                    style="color: #1a2d50"
                                    href="https://triomarkets.eu"
                                    >triomarkets.eu</a
                                  ></font
                                ></span
                              >
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr height="30" v-if="license !== 'edr'">
                      <td colspan="2" valign="bottom">
                        <table border="0" cellpadding="0" cellspacing="0">
                          <tr>
                            <td>
                              <a
                                href="https://www.facebook.com/triomarketsnews/"
                                target="_blank"
                                ><img
                                  width="30"
                                  height="25"
                                  src="../assets/img/signature/facebook.png"
                              /></a>
                            </td>
                            <td>
                              <a
                                href="https://www.linkedin.com/company/triomarkets"
                                target="_blank"
                                ><img
                                  width="30"
                                  height="25"
                                  src="../assets/img/signature/linkedin.png"
                              /></a>
                            </td>
                            <td>
                              <a
                                href="https://www.youtube.com/channel/UC_wQCTAHWrmDJqN0V7xbuBw"
                                target="_blank"
                                ><img
                                  width="30"
                                  height="25"
                                  src="../assets/img/signature/youtube.png"
                              /></a>
                            </td>
                            <td>
                              <a
                                href="https://twitter.com/TrioMarkets"
                                target="_blank"
                                ><img
                                  width="30"
                                  height="25"
                                  src="../assets/img/signature/twitter.png"
                              /></a>
                            </td>
                            <td>
                              <a
                                href="https://www.instagram.com/triomarkets/"
                                target="_blank"
                                ><img
                                  width="30"
                                  height="25"
                                  src="../assets/img/signature/instagram.png"
                              /></a>
                            </td>
                            <td v-if="signature.skype">
                              <a
                                :href="'skype:' + signature.skype + '?chat'"
                                target="_blank"
                                ><img
                                  width="30"
                                  height="25"
                                  src="../assets/img/signature/skype.png"
                              /></a>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </span>
    <br />
    <table style="table-layout: fixed" cellpadding="0" cellspacing="0">
      <tr>
        <td
          colspan="3"
          align="justify"
          style="text-align: justify; text-justify: inter-word"
        >
          <span
            style="
              font-size: 7pt;
              font-family: Helvetica, serif;
              color: #333333;
            "
          >
            <p v-if="license == 'edr'">
              Disclaimer: TrioMarkets™ is the trading name of EDR Financial Ltd,
              registered as a Cyprus Investment Firm («CIF») with the
              registration number (HE336081), under the Laws of the Republic of
              Cyprus. Licensed and regulated by the Cyprus Securities and
              Exchange Commission («CySEC») under license number
              <a
                style="color: #1a2d50"
                href="https://www.cysec.gov.cy/en-GB/entities/investment-firms/cypriot/71266/"
                target="_blank"
                >268/15</a
              >, in accordance with Markets in Financial Instruments Directive
              II («MiFID ΙΙ») and Markets in Financial Instruments Directive
              («MiFID»), to the extent it is still applicable. All retail client
              funds are insured by the Investor Compensation Fund (Subject of
              eligibility).
            </p>
            <p v-if="license == 'benor' || license == 'dubai'">
              Disclaimer: <strong>TrioMarkets™ (Global)</strong> is a trading
              name licensed to <strong>BENOR Capital Ltd</strong> which is
              registered as an Investment Dealer (Broker) with registration
              number 160496. Licensed by the Financial Services Commission
              (Mauritius) under license number
              <a
                style="color: #1a2d50"
                target="_blank"
                href="https://www.fscmauritius.org/en/supervision/register-of-licensees/register-of-licensees-details?licence_no=C118023678&key=&cat=_GB&code="
              >
                C118023678</a
              >. Registered Office: The Catalyst, Level 2, Suite 201, Plot 40,
              Silicon Avenue, Ebene, Mauritius and has the license to perform
              operations under the trading name TrioMarkets™
            </p>
            <p v-if="license == 'dubai'">
              <strong>Benor capital Ltd- Rep. Office</strong> is a
              representative office/Branch of
              <strong>Benor Capital Ltd.</strong> (Mauritius) located in the
              United Arab Emirates - Dubai under the DED License number
              <a style="color: #1a2d50; text-decoration: underline"> 1082819</a
              >. Registered Office: The Bayswater Tower , Office 1212, Marasi
              Drive, Business Bay, Dubai, United Arab Emirates and has the
              license to perform marketing and commercial activities under the
              trading name <strong>TrioMarkets™</strong>.
            </p>
            <p v-if="license == 'edr'">
              Risk Warning: Trading in Forex and Contracts for Difference (CFDs)
              is highly speculative and involves a substantial risk of loss.
              Choosing a higher leverage on those products can lead to high
              losses. Following portfolio management or copying the trading
              activity of other traders, even though they may be professional
              traders (physical or legal persons) is not obligatory and cannot
              guarantee success. You should not trade CFDs and Forex either by
              yourself or with the use of an expert advisor (robot), or copy the
              trading activity of other traders, unless you are prepared to
              sustain a total loss of your initial investment (deposit).
              Therefore, Forex and CFDs may not be suitable for all investors.
              Before trading, assess your financial condition and your level of
              experience and only invest with money you can afford to lose. Past
              performance or the use of financial indicators are not reliable
              sources of information and cannot be indicative of future results.
              Any material used, published or distributed by TrioMarkets is used
              for marketing or educational –where applicable-purposes only and
              in no case can they be considered as investment advice or strategy
              or recommendation, implied or explicit, with respect to the
              trading of a financial instrument. If necessary, seek for
              independent advice. Please ensure that you fully understand the
              risks involved. For more information, please refer to our
              <a
                style="color: #1a2d50"
                href="https://www.triomarkets.eu/legal-documents/EDR/Risk-Disclosure-Policy.pdf"
                target="_blank"
                >Risk Disclosure Policy</a
              >.
            </p>
            <p v-if="license == 'benor'">
              <strong>Risk Warning:</strong> Trading in Forex and Contracts for
              Difference (CFDs) is highly speculative and involves a substantial
              risk of loss. Choosing a higher leverage on those products can
              lead to high losses. Past performance or the use of financial
              indicators are not reliable sources of information and cannot be
              indicative of future results. Any material used, published or
              distributed by TrioMarkets™ is used for marketing or educational –
              where applicable-purposes only and in no case can they be
              considered as investment advice or strategy or recommendation,
              implied or explicit, with respect to the trading of a financial
              instrument. If necessary, seek for independent advice. Please
              ensure that you fully understand the risks involved. For more
              information, please refer to our Risk Disclosure Statement.
              <a
                style="color: #1a2d50"
                href="https://www.triomarkets.com/wp-content/uploads/2024/07/Benor_Capital_Ltd-Risk_Disclosure_Statement.pdf"
                target="_blank"
                >Risk Disclosure Policy</a
              >.
            </p>
            <p v-if="license == 'dubai'">
              Risk Warning: Trading in Forex and Contracts for Difference (CFDs)
              is highly speculative and involves a substantial risk ofloss.
              Choosing a higher leverage on those products can lead to high
              losses. Following portfolio management or copying thetrading
              activity of other traders, even though they may be professional
              traders (physical or legal persons) is notobligatory and cannot
              guarantee success. You should not trade CFDs and Forex either by
              yourself or with the use of anexpert advisor (robot), or copy the
              trading activity of other traders, unless you are prepared to
              sustain a total loss ofyour initial investment (deposit).
              Therefore, Forex and CFDs may not be suitable for all investors.
              Before trading, assessyour financial condition and your level of
              experience and only invest with money you can afford to lose. Past
              performanceor the use of financial indicators are not reliable
              sources of information and cannot be indicative of future results.
              Anymaterial used, published or distributed by TrioMarkets is used
              for marketing or educational –where applicable-purposes onlyand in
              no case can they be considered as investment advice or strategy or
              recommendation, implied or explicit, with respectto the trading of
              a financial instrument. If necessary, seek for independent advice.
              Please ensure that you fullyunderstand the risks involved. For
              more information, please refer to our
              <a
                style="color: #1a2d50"
                href="https://www.triomarkets.com/enUae/company/legal-documents"
                target="_blank"
                >Risk Disclosure Policy</a
              >.
            </p>
            <p v-if="license == 'edr'">
              Invest responsibly: CFDs are complex instruments and come with a
              high risk of losing money rapidly due to leverage.
              {{ signature.risk }}% of retail investor accounts lose money when
              trading CFDs with this provider. You should consider whether you
              understand how CFDs work and whether you can afford to take the
              high risk of losing your money.
            </p>
            <p>
              The information transmitted is intended only for the person or
              entity to which it is addressed and may contain confidential
              and/or privileged material. Any review, retransmission,
              dissemination or other use of, or taking of any action in reliance
              upon, this information by persons or entities other than the
              intended recipient is prohibited. If you received this in error,
              please contact the sender and delete the material from any
              computer. This email has been scanned for viruses but the sender
              does not accept any responsibility or liability for any damage
              that you suffer as a result of viruses.
            </p>
          </span>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    signatureID: {
      type: String,
      required: true,
    },
    signature: {
      type: Object,
      required: true,
    },
    license: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
div,
table,
tbody,
td,
tfoot,
th,
thead,
tr {
  user-select: all;
  border: none !important;
}
</style>
